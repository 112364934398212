<template>
    <template v-if="!isLoadingBil">
        <section class="aa-contenedor-basico fade-in">
            <div class="aa-contenedor-basico-portada">
                <img src="../../../assets/img/fotos/conversacion.jpg" alt="Recursos auxiliar conversación" class="portada" v-on:load="onLoaded" v-show="loaded" />
                <div style="width:100%; height: 327px" v-show="!loaded">
                    <LoadingVue />
                </div>
            </div>

            <div class="actividades-auxiliar mt-3">
                <h2 class="aa-text-h2"> Actividades {{bilingue.nombre}} </h2>

                <div class="actividad mb-3" v-for="({id, imagen, descripcion}, index) in bilingue.actividades" :key="id">
                    <div class="content text-start" v-html="descripcion"></div>
                    <img :src="imagen" alt="Foto actividad" class="m-auto" />
                    <div class="separador" v-if="index > 0"></div>
                </div>


            </div>

        </section>
    </template>
</template>

<script>
import { defineAsyncComponent, watch } from '@vue/runtime-core'
import { useRoute } from 'vue-router'

import useBilin from '../composables/useBilin'


export default {
    components: {  
        LoadingVue: defineAsyncComponent( () => import('../components/Loading.vue')), 
    },
    setup() {
        const route = useRoute()
        const { isLoadingBil, loaded, bilingue, getActividadesAuxiliar } = useBilin()
        getActividadesAuxiliar(route.params.id)

        watch(
            () => route.params.id,
            () => getActividadesAuxiliar(route.params.id),
        )

        return {
            isLoadingBil,
            bilingue,
            loaded,
            onLoaded: () => loaded.value = true,
        }
    }
}
</script>

<style lang="sass" scoped>

.actividades-auxiliar
    img
        width: 50%
    .actividad
        padding-left: 2rem
        padding-right: 2rem
        @include breakpoint(lg)
            padding-left: 3rem
            padding-right: 3rem

    .separador
        width: 100%
        height: 8px
        background-color: #18d580
        margin-top: 20px
        position: relative
        left: -20px
</style>